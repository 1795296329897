import React, { Fragment, useEffect } from 'react';
import Head from 'next/head';
import Router, { useRouter } from 'next/router';
import NProgress from 'nprogress';
import apiClient from 'src/apiClient';
import { Provider } from 'components/auth/provider';
import 'styles/tailwind.css';
import 'nprogress/nprogress.css';
import 'styles/vendors.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import dynamic from 'next/dynamic';

if (process.env.NEXT_PUBLIC_DEVELOPMENT_ENVIRONMENT === 'testing') {
  dynamic(() => import('src/mocks'));
}

Router.events.on('routeChangeStart', () => NProgress.start());
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

const client = apiClient();

function MyApp({ Component, pageProps }) {
  const router = useRouter();
  const { query } = router;

  useEffect(() => {
    if (Object.keys(query).length > 0 && (query.utm_source || query.utm_medium || query.utm_campaign || query.utm_rm)) {
      localStorage.setItem('utmParams', JSON.stringify(query));
    }
  }, [query]);

  return (
    <>
      <Head>
        <meta
          name='viewport'
          content='width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no'
          key='viewport'
        />
      </Head>
      <Provider client={client}>
        <Component {...pageProps} />
      </Provider>
    </>
  );
}

export default MyApp;
